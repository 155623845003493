<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      max-height="420"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
        </v-layout>
    </v-img>
    <v-container  grid-list-md text-center pt-12 style="padding-top:10px !important;">
      <v-container
        fluid
        grid-list-sm
        class="text-left"
      >
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" > "></v-breadcrumbs>
        </div>
        <v-layout wrap>
          <v-flex class="text-left">
            <span v-html="contact.contact">{{contact.contact}}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    contact: {
      'contact': null
    },
    breadcrumbs: []
  }),
  created () {
    document.querySelector('#menu_contact').classList.add('v-btn--active')
    this.getContact()
    this.breadcrumbs = [
      { text: '首页', disabled: false, href: '/' },
      { text: '联系我们', disabled: true }
    ]
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getContact () {
      this.https.get('contact').then(response => {
        if (response.code === 0) {
          this.contact = response.data
        }
      })
    }
  }
}
</script>
<style>
  .v-application ul, .v-application ol {
    padding-left: 0;
  }
</style>
